export const GAME = {
  SNAKE: {
    NAME: "snake",
    SLUG: "snake",
    TITLE: "Clue: Snake Game",
    DESCRIPTION:
      "Beat the Game to Win A Clue. Guide the Snake using arrow keys to Capture 30 Lamps.",
  },
  TIC_TOE: {
    NAME: "tic_toe",
    SLUG: "tic-tac-toe",
    TITLE: "Clue: Tic Tac Toe",
    DESCRIPTION:
      "Beat the Game to Win A Clue. Get 3 in a Row before the time runs out.",
  },
  MATCHING: {
    NAME: "matching",
    SLUG: "matching",
    TITLE: "Clue: Lamp Match",
    DESCRIPTION:
      "Beat the Game to Win A Clue. Match the 2 Wish Lamp Cards before the time runs out.",
  },
};
